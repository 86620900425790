#brands-carousel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  white-space: nowrap;
  margin-top: 80px;
  -webkit-overflow-scrolling: touch;
}

#brands-container {
  padding: 20px 0;
  display: flex;
  align-items: center;
  width: 80%;
  overflow-x: scroll;
}

#brands-container:-webkit-scrollbar {
  display: none;
}

#brands-container > img {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  aspect-ratio: 3.2/5;
  object-fit: cover;
  border-radius: 20px;
  width: 24%;
  margin: 0 0.5%;
  box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 8px 0 rgba(0, 0, 0, 0.2);
}
#scroll-right, #scroll-left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

#scroll-left {
  left: 5vw;
}

#scroll-right {
  right: 5vw;
}
