.banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.banner-content {
  width: 85vw;
  height: 70vh;
  object-fit: cover;
  object-position: 50% 0%;
  border-radius: 50px;
}

hr {
  display: block;
  border: 0;
  border-top: 3.5px dashed #777;
}