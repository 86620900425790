@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;1,300;1,400&display=swap');

#hero {
  background-image: url("./images/banner-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 60px;
  height: 100vh;
  width: 100%;
  margin-bottom: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#logo-container {
  position: relative;
  width: 727px;
  height: 100vh;
  background-color: transparent;
}

#fi-ma-logo {
  position: absolute;
  top: 41%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  object-fit: cover;
  padding: 20px;
  filter: brightness(0.87);
  animation: 4s slowly-appear-logo ease-out;
}

#fi-ma-title {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 90px;
  width: 300px;
  text-align: center;
  color: #ccc;
  animation: 4s slowly-appear-title ease-out;
}

#fi-ma-slogan {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 34px;
  font-weight: 400;
  color: #aaa;
  animation: 4s slowly-appear-slogan linear;
}

#banner-left {
  position: absolute;
  left: 5%;
  width: 400px;
  height: 700px;
  border-radius: 25px;
  object-fit: cover;
  animation: 2.5s banner-left-slide-in ease-out;
}

#banner-right {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0%, -50%);
  width: 400px;
  height: 700px;
  border-radius: 25px;
  object-fit: cover;
  animation: 2.5s banner-right-slide-in ease-out;
}

#scroll-more-arrows {
  position: absolute;
  top: 96%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 1s;
  -webkit-transition: all 1s;
  height: 70px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#scroll-more-arrow1 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 30px;
  fill: white;
  animation: fade-in 1s forwards, arrow1-jump 2s ease-out infinite;
  animation-delay: 3s;
}

#scroll-more-arrow2 {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  opacity: 0;
  width: 30px;
  fill: white;
  animation: fade-in 1s forwards, arrow2-jump 2s ease-out infinite;
  animation-delay: 3.25s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slowly-appear-logo {
  0% {
    width: 37px;
    opacity: 0.2;
  }
  20% {
    width: 400px;
    opacity: 1;
  }
  35% {
    width: 410px;
  }
  40% {
    width: 400px;
  }
  100% {
    width: 400px;
  }
}

@keyframes slowly-appear-title {
  0% {
    font-size: 67.5px;
    opacity: 0.2;
  }
  20% {
    font-size: 90px;
    opacity: 1;
  }
  35% {
    font-size: 92.25px;
  }
  40% {
    font-size: 90px;
  }
  100% {
    font-size: 90px;
  }
}

@keyframes slowly-appear-slogan {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes banner-left-slide-in {
  0% {
    left: -30%;
  }
  60% {
    left: -30%;
  }
  80% {
    left: 5%;
  }
  95% {
    left: 5.5%;
  }
  100% {
    left: 5%;
  }
}

@keyframes banner-right-slide-in {
  0% {
    right: -30%;
  }
  60% {
    right: -30%;
  }
  80% {
    right: 5%;
  }
  95% {
    right: 5.5%;
  }
  100% {
    right: 5%;
  }
}

@keyframes arrow1-jump {
  0% {
    transform: translate(-50%, -50%);
  }
  40% {
    transform: translate(-50%, -60%);
  }
  60% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes arrow2-jump {
  0% {
    transform: translate(-50%, 0%);
  }
  40% {
    transform: translate(-50%, -10%);
  }
  60% {
    transform: translate(-50%, 10%);
  }
  100% {
    transform: translate(-50%, 0%);
  }
}

@media screen and (max-width: 1400px) {
  #banner-right {
    display: none;
  }
  #banner-left {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  #logo-container{
    width: 500px;
  }
}

@media screen and (max-width: 450px) {
  #fi-ma-logo {
    animation-duration: 8s;
  }

  #fi-ma-title {
    top: 60%;
    font-size: 70px;
    animation: 2s fade-in ;
  }

  #fi-ma-slogan {
    font-size: 25px;
    bottom: 25%;
    width: 100%;
    animation-duration: 1.5s;
  }

  #scroll-more-arrows {
    top: 80%;
  }

  #whatsapp-logo-box {
    top: 80vh;
    left: 40px;
  }
}
