@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding-bottom: 100px;
  font-family: 'League Spartan', sans-serif;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: hidden;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

h4 {
  font-size: 20px;
  margin: 10px 0;
}

h2 {
  font-size: 46px;
  line-height: 54px;
  margin: 10px 0;
}

h1 {
  font-size: 50px;
  line-height: 64px;
  margin: 10px 0;
}

p {
  font-size: 20px;
  color: #4f465b;
  margin: 15px 0px 20px 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
