.product-card {
  width: 240px;
  display: flex;
  padding: 10px;
  margin: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  background-color: white;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
  border: 0.125rem solid #777;
  border-radius: 0.5rem;
}

.product-card:focus, .product-card:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.product-card:active {
  -webkit-transform: scale(.9);
  transform: scale(.9);
}


.product-title {
  font-size: 16px;
  height: 55px;
  text-align: center;
  display: flex;
  align-items: center;
  margin: 10px auto;
  padding: 0 10px;
}

.product-image {
  width: 210px;
  aspect-ratio: 3/4;
  object-fit: cover;
  border-radius: 4px;
  border: 2px solid #777;
}

@media screen and (max-width: 450px) {
  .product-card {
    width: 140px;
    margin: 8px;
  }
  .product-title {
    font-size: 13px;
    padding: 0;
  }
}
