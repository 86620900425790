#product-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  padding: 0 8vw;
  min-height: 85vh;
}

.product-link {
  text-decoration: none;
  color: black;
}

