#list-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

}

#list-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#list-title {
  font-size: 2.4rem;
  font-weight: 400;
  text-shadow: 0px 2px 1px rgba(0,0,0,0.5);
  text-align: center;
  margin: 1rem 0.25rem 10px 0.25rem;
}

#loader {
  display: none;
  width: 25px;
  height: 25px;
  margin: 12px 15px 10px 0;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid black;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}

@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}

@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}

@media screen and (max-width: 850px) {
  #list-title {
    margin-bottom: 0;
  }

  #list-header {
    flex-direction: column;
  }

  #search-box {
    position: static;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 325px) {
  #list-title {
    line-height: 50px;
  }

  #search-box {
    margin-top: 10px;
  }
}

